import { useState, useRef, useCallback, useEffect } from "react";

import { PrinterOutlined } from "@ant-design/icons";
import { Select, Col, Row, Form, Tooltip, notification, Button } from "antd";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";

import useCustomerReport from "../../api/hooks/useCustomerReport";
import useDeviceByDeviceId from "../../api/hooks/useDeviceByDeviceId";
import usePropertyPreferences from "../../api/hooks/usePropertyPreferences";
import WaterDropIcon from "../../assets/icons/WaterDropIcon";
import { isValidDate } from "../../helpers/dates";
import useProfileSettings from "../../hooks/useProfileSettings";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../Loader/Loader";
import Body from "./Customer/Body";
import Chart from "./Customer/Chart";
import Filters from "./Customer/Filters";
import Footer from "./Customer/Footer";
import Header from "./Customer/Header";

import "./Layout/Print.css";

function CustomerReport() {
  const [isLoading, setIsLoading] = useState(false);

  const [pageSizeAndOrientation, setPageSizeAndOrientation] =
    useState("A4 portrait");
  const [generateReportForm] = Form.useForm();
  const initialFormValues = useRef({});
  const [propertyId, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [deviceUniqueId, setDeviceUniqueId] = useState(null);
  const [reportDate, setReportDate] = useState(null);
  const [costOfWaterPerUOM, setCostOfWaterPerUOM] = useState(0);
  const [administrationFees, setAdministrationFees] = useState(0);
  const [additionalFees, setAdditionalFees] = useState(0);

  const { userSettings } = useProfileSettings();
  const window = useWindowSize();
  const [waterUOM, setWaterUOM] = useState("litres");

  // response data
  const [totalConsumption, setTotalConsumption] = useState("");
  const [waterCharges, setWaterCharges] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);

  const {
    data: property,
    propertyStatus,
    refetch: getPropertyPreferences,
  } = usePropertyPreferences(propertyId, {
    enabled: false,
    cacheTime: 0,
  });

  const {
    data: Device,
    deviceStatus,
    refetch: getDeviceByDeviceId,
  } = useDeviceByDeviceId(deviceUniqueId, {
    enabled: false,
    cacheTime: 0,
  });

  const printOptions = [
    {
      value: "A4 portrait",
      label: "Portrait",
    },
    {
      value: "A4 landscape",
      label: "Landscape",
    },
  ];

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangePrintOption = (value) => {
    setPageSizeAndOrientation(value);
  };

  const setPageSize = (pageSizeAndOrientation) => {
    const style = document.createElement("style");
    style.innerHTML = `@page {size: ${pageSizeAndOrientation}}`;
    style.id = "page-orientation";
    document.head.appendChild(style);
  };

  useEffect(() => {
    setPageSize(pageSizeAndOrientation);
    return () => {
      const child = document.getElementById("page-orientation");
      child.parentNode.removeChild(child);
    };
  }, [pageSizeAndOrientation]);

  const componentToPrintRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  useEffect(() => {
    setWaterUOM(userSettings.waterUnit);

    if (propertyId && costOfWaterPerUOM === 0) {
      getPropertyPreferences(propertyId).then(() => {
        setCostOfWaterPerUOM(
          property?.PropertyPreferences?.CostOfWater &&
            property?.PropertyPreferences?.CostOfWater > 0
            ? property?.PropertyPreferences?.CostOfWater
            : costOfWaterPerUOM,
        );
      });
    }
  }, [
    costOfWaterPerUOM,
    getPropertyPreferences,
    property?.PropertyPreferences?.CostOfWater,
    propertyId,
    userSettings.waterUnit,
  ]);

  const handleOnChangeFilterCallback = (
    propertyId,
    floorId,
    systemId,
    deviceId,
    deviceUniqueId,
    reportDate,
  ) => {
    setPropertyId(propertyId);
    setFloorId(floorId);
    setSystemId(systemId);
    setDeviceId(deviceId);
    setDeviceUniqueId(deviceUniqueId);
    setReportDate(reportDate);
  };

  const { refetch: getCustomerReport } = useCustomerReport(
    {
      filtersWithDeviceId: {
        deviceId: deviceId === "" ? null : deviceId,
        propertyId: propertyId === "" ? null : propertyId,
        floorId: floorId === "" ? null : floorId,
        systemId: systemId === "" ? null : systemId,
      },
      date: new Date(reportDate),
      costOfWaterPerUOM,
      waterUOM,
      administrationFees,
      additionalFees,
    },
    { enabled: false },
  );

  const handleReportGenerate = useCallback(() => {
    if (
      waterUOM &&
      isValidDate(reportDate) &&
      propertyId &&
      deviceId &&
      deviceUniqueId
    ) {
      setIsLoading(true);

      getCustomerReport().then((response) => {
        if (response && response.isSuccess) {
          setTotalConsumption(
            response.data.TotalConsumption
              ? response.data.TotalConsumption.toString()
              : "",
          );
          setWaterCharges(response.data.WaterCharges);
          setAdministrationFees(response.data.AdministrationFees);
          setAdditionalFees(response.data.AdditionalFees);
          setTotalCharges(response.data.TotalCharges);
        } else {
          notification.info({
            message: (
              <span className="uppercase tracking-widest">Loading data...</span>
            ),
            description: (
              <span className="uppercase tracking-widest">
                {response && response.error && response.error.response
                  ? response.error.response.data[0]
                  : ""}
              </span>
            ),
            duration: 3,
            placement: "topRight",
          });

          // Clear report data if no consumption is found
          setTotalConsumption("");
          setWaterCharges(0);
          setAdministrationFees(0);
          setAdditionalFees(0);
          setTotalCharges(0);
        }
      });

      getDeviceByDeviceId(deviceId).then(() => {
        setIsLoading(false);
      });
    }

    setIsLoading(false);
  }, [
    getCustomerReport,
    getDeviceByDeviceId,
    deviceUniqueId,
    deviceId,
    propertyId,
    reportDate,
    waterUOM,
  ]);

  return (
    <>
      <div className="flex-1 mt-6 md:mt-1 flex lg:items-center flex-col lg:flex-row">
        {window?.width > 768 && <h1 className="page-title">Customer Report</h1>}
      </div>

      <Row>
        <Button
          className="ml-4"
          size="small"
          type="primary"
          onClick={handleReportGenerate}
        >
          Generate Report
        </Button>
      </Row>
      <Row className="w-full xxl:flex-row flex-col-reverse">
        <Col className="w-[90%] xl:w-[50%] flex xs:flex-col-reverse sm:flex-row">
          <Col className="p-2 w-full">
            <Row className="justify-start mx-2">
              <Col className="flex gap-2 w-full">
                <Col className="flex flex-col xs:w-32 xs:h-32 sm:w-24 sm:h-24 xl:w-20 xl:h-20 text-center items-center rounded-md bg-triple-white py-2 px-4 xs:gap-4 sm:gap-2 xl:gap-1">
                  <Col className="text-triple-background uppercase xs:text-[10px] sm:text-[8px]">
                    Customer
                  </Col>
                  <Col className="xs:w-20 xs:h-20 sm:w-14 sm:h-14 xl:w-12 xl:h-12 border-[5px] border-triple-blue border-solid rounded-full flex items-center justify-center">
                    <WaterDropIcon
                      className="xs:w-12 xs:h-12 sm:w-8 sm:h-8 lg:w-6 lg:h-6 align-sub"
                      strokeColor="#757782"
                    />
                  </Col>
                </Col>
                <Col className="w-full">
                  <Form
                    form={generateReportForm}
                    name="generate-report-form"
                    onFinish={handleReportGenerate}
                    initialValues={initialFormValues.current}
                    className="w-full"
                  >
                    <Filters
                      handleOnChangeFilterCallback={
                        handleOnChangeFilterCallback
                      }
                    />
                  </Form>
                </Col>
              </Col>
            </Row>
            <Row className="w-full justify-start">
              {propertyStatus === "loading" &&
              deviceStatus === "loading" &&
              isLoading === true ? (
                <Loader />
              ) : (
                <Col ref={componentToPrintRef} className="pt-2">
                  <Row className="m-2">
                    <Header
                      property={property}
                      totalCharges={totalCharges}
                      setCostOfWaterPerUOM={setCostOfWaterPerUOM}
                    />
                  </Row>
                  <Row className="m-2">
                    <Body
                      property={property}
                      device={Device}
                      totalConsumption={totalConsumption}
                      waterCharges={waterCharges}
                      administrationFees={administrationFees}
                      additionalFees={additionalFees}
                      setAdministrationFees={setAdministrationFees}
                      setAdditionalFees={setAdditionalFees}
                      totalCharges={totalCharges}
                      waterUOM={waterUOM}
                      previousMonthDate={
                        reportDate
                          ? dayjs(reportDate).subtract(1, "month").add(2, "day")
                          : dayjs().subtract(1, "month")
                      }
                      reportDate={reportDate ? new Date(reportDate) : null}
                    />
                  </Row>
                  {property && reportDate && (
                    <Col className="mx-2 mt-2 mb-12">
                      <Chart
                        propertyId={property?.Id.toString()}
                        floorId={floorId.toString()}
                        systemId={systemId.toString()}
                        date={dayjs(reportDate)}
                      />
                    </Col>
                  )}
                  <Row className="m-2">
                    <Footer property={property} />
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Col>
        <Col className="flex">
          <Col className="mt-2">
            <Tooltip title="Print Report" color="#06a5d3" key="print">
              <PrinterOutlined
                className="text-26 cursor-pointer text-triple-white hover:text-triple-blue"
                onClick={() => handlePrint()}
              />
            </Tooltip>
          </Col>
          <Col className="mt-2 ml-2">
            <Select
              size="small"
              placeholder="Select Print Option"
              optionFilterProp="printOption"
              onChange={onChangePrintOption}
              filterOption={filterSelect}
              defaultValue={pageSizeAndOrientation}
              options={printOptions}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
}

export default CustomerReport;
