import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import Search from "antd/es/input/Search";
import { useLocation, useParams } from "react-router-dom";

import { usePropertyOverviewFloorsMetadata } from "../../../../../api/hooks/usePropertyOverview";
import FloorCard from "./FloorCard";

function FloorList() {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const { property } = location.state || {};
  const { propertyId } = useParams();

  // Get PropertyOverview Floors Metadata.
  const {
    data: floors,
    status,
    refetch: getPropertyOverviewFloorsMetadata,
  } = usePropertyOverviewFloorsMetadata(propertyId, {
    search: debouncedSearchQuery,
  });

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <div className="content-wrapper overflow-y-auto">
      <div className="flex flex-col-reverse sm:flex-row sm:items-center mb-4 md:mb-2 w-full">
        <div className="mt-6 md:mt-1 flex-1 flex flex-col md:flex-row md:items-center mr-auto">
          <h1 className="page-title mb-0">{property?.Name}</h1>
        </div>
        <Search
          className="w-full md:w-fit mr-4"
          placeholder="Search Floors"
          onSearch={onSearch}
          onChange={handleSearchChange}
          allowClear
          size="middle"
        />
      </div>
      {status === "loading" &&
        Array.from({ length: 3 }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 3,
            }}
            key={el}
          />
        ))}

      {status === "success" && (floors?.length === 0 || !floors) && (
        <div className="p-6 text-white text-opacity-80">
          <FrownOutlined className="text-xl mr-2" />
          We couldn`t find any matches
        </div>
      )}

      {status === "success" && floors?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
          {floors
            ?.sort(
              (firstElement, secondElement) =>
                firstElement.Id - secondElement.Id,
            )
            .map((floor) => (
              <div className="sm:my-2 my-4" key={floor.Id}>
                <FloorCard
                  key={`${floor.Id}_${floor.Name}`}
                  objectFloor={floor}
                  refetchPropertyFloors={getPropertyOverviewFloorsMetadata}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default FloorList;
