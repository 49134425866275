import { useEffect, useRef, useState } from "react";

import { EditOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio, Select, Switch, Tooltip } from "antd";
import PropTypes from "prop-types";

import { usePropertyOverviewMetadata } from "../../../api/hooks/usePropertyOverview";
import {
  useGetCmsOperator,
  useUpdateCmsOperator,
} from "../../../api/hooks/useUsersManagement";
import {
  useHandleFieldsChange,
  useHandleUpdateUser,
} from "./hooks/useUserHooks";
import UsersPermissionsList from "./UsersPermissionsList/UsersPermissionsList";

const propTypes = {
  setIsFormDirty: PropTypes.func,
  setFormReference: PropTypes.func,
  userId: PropTypes.number.isRequired,
};

const defaultPropTypes = {
  setIsFormDirty: () => {},
  setFormReference: () => {},
};

function EditUser({ setIsFormDirty, setFormReference, userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [licenseValue, setLicenseValue] = useState();
  const [selectedProperties, setProperties] = useState([]);
  const [accessToAll, setAccessToAll] = useState(false);
  const [accessToAllDisabled, setAccessToAllDisabled] = useState(false);
  const [editUserForm] = Form.useForm();
  const initialFormValues = useRef({});

  const options = [];
  const { data: properties } = usePropertyOverviewMetadata();
  if (properties) {
    properties.forEach((item) => {
      options.push({
        label: item.Name,
        value: item.Id,
      });
    });
  }

  const updateCmsOperatorMutationFn = useUpdateCmsOperator();
  const { refetch: getUserForManagement } = useGetCmsOperator(userId, {
    enabled: false,
  });

  useEffect(() => {
    getUserForManagement(userId).then((response) => {
      const { data } = response;
      const licenseValue = data?.Value.Permission;
      initialFormValues.current = {
        UserId: data?.Value.UserId,
        Username: data?.Value.Username,
        FirstName: data?.Value.FirstName,
        LastName: data?.Value.LastName,
        FullName: `${data?.Value.FirstName} ${data?.Value.LastName}`,
        Email: data?.Value.Email,
        PhoneNumber: data?.Value.PhoneNumber,
        Permission: data?.Value.Permission,
        LicenseKey: data?.Value.LicenseKey,
        LicenseValue: licenseValue,
        PurchaseKey: data?.Value.PurchaseKey,
        AccessToAll: data?.Value.OperatorPermission?.AccessToAll,
        PropertyIds: data?.Value.OperatorPermission
          ?.OperatorPermissionsProperties
          ? data?.Value.OperatorPermission?.OperatorPermissionsProperties?.map(
              (x) => x.PropertyId,
            )
          : [],
      };
      setLicenseValue({ value: licenseValue, checked: true });
      if (licenseValue === "Admin" || licenseValue === "Existing User") {
        setAccessToAll(true);
        setAccessToAllDisabled(true);
        initialFormValues.current.AccessToAll = true;
      } else {
        setAccessToAll(data?.Value?.OperatorPermission?.AccessToAll);
      }
      editUserForm.setFieldsValue(initialFormValues.current);
    });
  }, [userId, getUserForManagement, editUserForm]);

  useEffect(() => {
    setFormReference(editUserForm);
  }, [editUserForm, setFormReference]);

  const HandleSubmit = async (formValues) => {
    setIsLoading(true);
    const response = await useHandleUpdateUser(
      userId,
      formValues,
      setIsLoading,
      setIsFormDirty,
      updateCmsOperatorMutationFn,
    );
    const responseDataValue = response?.data?.Value;
    initialFormValues.current = {
      ...initialFormValues.current,
      FirstName: responseDataValue.FirstName,
      LastName: responseDataValue.LastName,
      AccessToAll: responseDataValue.AccessToAll,
      PhoneNumber: responseDataValue.PhoneNumber,
      PropertyIds: responseDataValue.PropertyIds,
    };
    setDisableSaveChanges(true);
  };

  const HandleFieldsChange = (changedFields) => {
    useHandleFieldsChange(
      changedFields,
      editUserForm,
      initialFormValues,
      setDisableSaveChanges,
      setIsFormDirty,
    );
  };

  const handleOnValuesChange = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
  };

  // TODO kpeshterski: move this duplicate code to be used across CreateUser.jsx & EditUser.jsx
  const omittedValuesTooltip = (omittedValues) => (
    <Tooltip
      styles={{
        root: {
          pointerEvents: "none",
        },
      }}
      color="#5a5a5a"
      title={omittedValues.map(({ label }) => label).join(", ")}
    >
      + {omittedValues.length} ...
    </Tooltip>
  );

  return (
    <div className="content-wrapper overflow-y-auto create-user-form mr-3">
      <Form
        form={editUserForm}
        name="edit-user-form"
        className="w-full"
        onFinish={HandleSubmit}
        onFieldsChange={HandleFieldsChange}
        onValuesChange={handleOnValuesChange}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        labelAlign="left"
        initialValues={{
          ...initialFormValues?.current,
        }}
      >
        <div className="create-new-user-title">
          <span className="text-center mr-1 uppercase tracking-wider">
            edit user
          </span>
          <EditOutlined className="text-lg" />
        </div>
        <div className="border border-solid rounded p-1 mt-6 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Full Name"
            name="FullName"
            rules={[
              { required: true, message: "Full Name field is required." },
            ]}
          >
            <Input size="small" maxLength={100} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Mobile"
            name="PhoneNumber"
            rules={[
              {
                required: true,
                message: "Phone Number field is required.",
              },
            ]}
          >
            <Input size="small" maxLength={15} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Email"
            name="Email"
            rules={[
              { required: true, message: "Email field is required." },
              { type: "email", message: "Invalid email address." },
            ]}
          >
            <Input size="small" maxLength={100} disabled />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item className="mb-0" label="Access To All" name="AccessToAll">
            <Switch
              size="small"
              checked={accessToAll}
              disabled={accessToAllDisabled}
              onChange={(checked) => {
                setAccessToAll(checked);
              }}
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            name="PropertyIds"
            label="Property"
            layout="inline"
            hidden={accessToAll}
          >
            <Select
              mode="multiple"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="w-full"
              size="small"
              value={selectedProperties}
              onChange={(values) => {
                setProperties(values);
                setIsFormDirty(true);
                setDisableSaveChanges(false);
              }}
              maxTagCount="responsive"
              options={options}
              maxTagPlaceholder={(omittedValues) =>
                omittedValuesTooltip(omittedValues)
              }
            />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label="Purchase Plan"
            name="PurchaseKey"
            rules={[
              { required: true, message: "Purchase Plan field is required." },
            ]}
          >
            <Select className="w-full" placeholder="" size="small" disabled />
          </Form.Item>
        </div>
        <div className="border border-solid border-triple-blue rounded p-1 mt-4">
          <Form.Item
            className="mb-0"
            label="License"
            name="LicenseValue"
            rules={[
              {
                required: true,
                message: "License field is required.",
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              className="flex w-full cursor-pointer"
              size="small"
              onChange={(event) => {
                setLicenseValue({
                  value: event?.target?.value,
                  checked: event?.target?.checked,
                });
              }}
            >
              <Radio.Button
                value="Operator"
                className="text-center flex-1 mx-1 rounded license-radio-btn"
                disabled={licenseValue?.value !== "Operator"}
              >
                <span className="font-light text-triple-white uppercase tracking-widest">
                  Operator
                </span>
              </Radio.Button>
              {licenseValue?.value === "Operator" ? (
                <UnlockOutlined style={{ fontSize: 20, color: "#06A5D3" }} />
              ) : (
                <LockOutlined style={{ fontSize: 20 }} />
              )}
              <Radio.Button
                value="User"
                className="text-center flex-1 mx-1 rounded license-radio-btn"
                disabled={licenseValue?.value !== "User"}
              >
                <span className="font-light text-triple-white uppercase tracking-widest">
                  User
                </span>
              </Radio.Button>
              {licenseValue?.value === "User" ? (
                <UnlockOutlined style={{ fontSize: 20, color: "#06A5D3" }} />
              ) : (
                <LockOutlined style={{ fontSize: 20 }} />
              )}
            </Radio.Group>
          </Form.Item>
        </div>

        <UsersPermissionsList formRef={editUserForm} />

        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-create-user-save-btn"
          disabled={disableSaveChanges}
          loading={isLoading}
        >
          Save
        </Button>
      </Form>
    </div>
  );
}

EditUser.propTypes = propTypes;
EditUser.defaultProps = defaultPropTypes;
export default EditUser;
