import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePropertyWaterConsumptionPercentageMonthly(
  options,
  key = "getPropertyWaterConsumptionPercentageMonthly",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyWaterConsumptionPercentageMonthly = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: "PropertyOverview/WaterConsumptionPercentage",
    }).then((res) => res.data);

    for (let index = 0; index < events.length; index += 1) {
      const element = events[index];
      if (
        element.Name === "Default Property" &&
        element.WaterConsuptionPercentage === 0
      ) {
        events.splice(index, 1);
      }
    }
    return events;
  };

  return useQuery([key], () => getPropertyWaterConsumptionPercentageMonthly(), {
    ...options,
  });
}

export default usePropertyWaterConsumptionPercentageMonthly;
