import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";

import {
  usePropertyOverviewMetadata,
  usePropertyOverviewStatuses,
  usePropertyOverviewWaterConsumption,
} from "../../../api/hooks/usePropertyOverview";
import useWindowSize from "../../../hooks/useWindowSize";
import SearchInput from "../../SearchInput/SearchInput";
import PropertyCard from "../PropertyCard/PropertyCard";

function PropertyList() {
  const window = useWindowSize();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const { data: properties, status: metadataQueryStatus } =
    usePropertyOverviewMetadata({
      search: debouncedSearchQuery,
    });

  const {
    data: overviewStatusesCollectionData,
    isLoading: overviewStatusesIsLoading,
  } = usePropertyOverviewStatuses();

  const {
    data: waterConsumptionCollectionData,
    isLoading: waterConsumptionIsLoading,
  } = usePropertyOverviewWaterConsumption();

  const filteredProperties = properties
    ?.sort((firstElement, secondElement) =>
      firstElement.Name.localeCompare(secondElement.Name),
    )
    .filter((p) => !(p.Name === "Default Property" && p.DevicesCount === 0));

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <div className="property-list">
      <div className="flex md:items-center mb-2 flex-col-reverse md:flex-row">
        <div className="flex-1 mt-6 md:mt-0 flex lg:items-center flex-col lg:flex-row">
          {window?.width > 768 && (
            <h1 className="page-title mb-0">Property Overview</h1>
          )}

          <div className="states lg:ml-2 xl:ml-4">
            <div>
              <span className="square square--blue">&nbsp;</span>
              <span>Neutral state</span>
            </div>
            <div>
              <span className="square square--orange">&nbsp;</span>
              <span>Negative state</span>
            </div>
          </div>
        </div>

        <SearchInput
          className="ml-auto w-full md:w-fit"
          placeholder="Search Properties"
          disabled={
            (!searchQuery && filteredProperties?.length === 0) ||
            metadataQueryStatus === "loading"
          }
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      {metadataQueryStatus === "loading" &&
        Array.from({ length: 5 }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 3,
            }}
            key={el}
          />
        ))}

      {metadataQueryStatus === "success" &&
        filteredProperties?.length === 0 && (
          <div className="p-6 text-white text-opacity-80">
            <FrownOutlined className="text-xl mr-2" />
            We couldn`t find any matches
          </div>
        )}

      {metadataQueryStatus === "success" && filteredProperties?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-3 lg:gap-x-0 mb-6">
          {filteredProperties.map((property) => (
            <PropertyCard
              key={property.Id}
              property={property}
              waterConsumptionCollectionData={waterConsumptionCollectionData}
              overviewStatusesCollectionData={overviewStatusesCollectionData}
              waterConsumptionIsLoading={waterConsumptionIsLoading}
              overviewStatusesIsLoading={overviewStatusesIsLoading}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default PropertyList;
