import RegularExpressions from "../../../../constants/RegExpressions";

function useHandleFieldsChange(
  changedFields,
  formInstance,
  initialFormValues,
  setDisableSaveChanges,
  setIsFormDirty,
) {
  const formInstanceValues = formInstance.getFieldsValue();
  const fieldName = changedFields[0]?.name[0];
  const fieldValue = changedFields[0]?.value;

  if (fieldName === "PhoneNumber") {
    if (fieldValue === "") {
      setDisableSaveChanges(true);
      formInstance.setFields([
        {
          name: "PhoneNumber",
          errors: ["Phone Number field is required."],
        },
      ]);
    } else {
      const isValidInput = RegularExpressions.NumbersOnly.test(fieldValue);
      if (isValidInput) {
        formInstance.setFields([{ name: "PhoneNumber", errors: [] }]);
      } else {
        setDisableSaveChanges(true);
        formInstance.setFields([
          {
            name: "PhoneNumber",
            errors: ["Phone Number should contain only digits."],
          },
        ]);
      }
    }
  }

  if (
    formInstanceValues?.FullName !== initialFormValues.current.FullName ||
    formInstanceValues?.PhoneNumber !== initialFormValues.current.PhoneNumber ||
    formInstanceValues?.Email !== initialFormValues.current.Email ||
    formInstanceValues?.AccessToAll !== initialFormValues.current.AccessToAll
  ) {
    setDisableSaveChanges(false);
    setIsFormDirty(true);
  }

  if (
    formInstanceValues?.FullName === initialFormValues.current.FullName &&
    formInstanceValues?.PhoneNumber === initialFormValues.current.PhoneNumber &&
    formInstanceValues?.Email === initialFormValues.current.Email &&
    formInstanceValues?.AccessToAll === initialFormValues.current.AccessToAll
  ) {
    setDisableSaveChanges(true);
    setIsFormDirty(false);
  }
}

export default useHandleFieldsChange;
